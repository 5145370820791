/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const ButtonContainer = styled.div`
  margin: 8px 0;
  width: 110px;
  border-bottom: 1px solid #e6e6e6;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
