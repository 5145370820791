import React from "react";
import { Text } from "@chakra-ui/react";
import useStore from "../../store";
import { ButtonContainer, ImageContainer } from "./styles";
import { HealthIcon, HeartRateIcon } from "./icons";

function Menu() {
  const reportTypes = useStore((state) => state.ReportTypes);
  const setReport = useStore((state) => state.setReport);
  const report = useStore((state) => state.report);

  const icons = (value, color) => {
    const data = {
      feelingScore: <HealthIcon color={color} />,
      heartRate: <HeartRateIcon color={color} />,
      respiratoryRate: <HeartRateIcon color={color} />,
    };
    return data[value];
  };

  return reportTypes.map((item) => {
    const active = item.value === report.value;
    const color = item.value === report.value ? "#3366FF" : "#464646";
    return (
      <ButtonContainer onClick={() => setReport(item)}>
        <ImageContainer>{icons(item.value, color)}</ImageContainer>
        <Text
          align="center"
          fontSize="xs"
          marginTop="0"
          paddingBottom="4"
          fontWeight={active ? "bold" : "medium"}
          color={color}
        >
          {item.name}
        </Text>
      </ButtonContainer>
    );
  });
}

export default Menu;
