import React from "react";

export const HealthIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width="48px"
      height="48px"
    >
      <path
        fill="#fff"
        d="M70,82H31c-6.6,0-12-5.4-12-12V31c0-6.6,5.4-12,12-12h39c6.6,0,12,5.4,12,12v39 C82,76.6,76.6,82,70,82z"
      />
      <path
        fill={color}
        d="M70.011,82.988h-39c-7.18,0-13-5.82-13-13v-39c0-7.18,5.82-13,13-13h39c7.18,0,13,5.82,13,13v39 C83.011,77.168,77.19,82.988,70.011,82.988z M20.011,30.988v39c0,6.075,4.925,11,11,11h39c6.075,0,11-4.925,11-11v-39 c0-6.075-4.925-11-11-11h-39C24.935,19.988,20.011,24.913,20.011,30.988z"
      />
      <path
        fill={color}
        d="M67.173,77.988H33.848c-5.985,0-10.837-4.852-10.837-10.837V33.825 c0-5.985,4.852-10.837,10.837-10.837h33.663c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5H33.848 c-5.433,0-9.837,4.404-9.837,9.837V67.15c0,5.433,4.405,9.838,9.838,9.838h33.325c5.433,0,9.837-4.404,9.837-9.837V48.488 c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5V67.15C78.011,73.136,73.158,77.988,67.173,77.988z M77.511,45.567 c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v4C78.011,45.344,77.787,45.567,77.511,45.567z M77.511,39.567c-0.276,0-0.5-0.224-0.5-0.5v-2c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v2 C78.011,39.344,77.787,39.567,77.511,39.567z"
      />
      <path
        fill="#f15b6c"
        d="M64.5,30.5c-2.551,0-4.777,1.369-6,3.408c-1.223-2.039-3.449-3.408-6-3.408c-3.866,0-7,3.134-7,7 c0,8,10.638,16,13,16s13-8,13-16C71.5,33.634,68.366,30.5,64.5,30.5z"
      />
      <path
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        d="M64.5,30.5 c-2.551,0-4.777,1.369-6,3.408c-1.223-2.039-3.449-3.408-6-3.408c-3.866,0-7,3.134-7,7c0,8,10.638,16,13,16s13-8,13-16 C71.5,33.634,68.366,30.5,64.5,30.5z"
      />
    </svg>
  );
};

export const HeartRateIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 48 48"
      viewBox="0 0 48 48"
      width="42px"
      height="42px"
    >
      <path
        fill={color}
        d="M44,25h-6c-0.27,0-0.52,0.11-0.71,0.29l-2.68,2.68L30.98,9.8C30.89,9.35,30.5,9.02,30.04,9c-0.47-0.02-0.88,0.28-1,0.72
		l-7.2,25.19l-6.91-17.28c-0.15-0.37-0.49-0.61-0.89-0.63c-0.4-0.02-0.76,0.2-0.94,0.55L9.38,25H4c-0.55,0-1,0.45-1,1s0.45,1,1,1h6
		c0.38,0,0.73-0.21,0.89-0.55l3-6.01l7.17,17.93C21.22,38.75,21.59,39,22,39c0.02,0,0.03,0,0.05,0c0.43-0.02,0.79-0.31,0.91-0.72
		l6.87-24.03l3.19,15.96c0.07,0.36,0.34,0.65,0.69,0.76c0.35,0.11,0.74,0.01,1-0.25L38.41,27H44c0.55,0,1-0.45,1-1S44.55,25,44,25z"
      />
    </svg>
  );
};
