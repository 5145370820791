import { useEffect } from "react";
import qs from "qs";
import { Container } from "./styles";
import useStore from "../../store";
import {
  Button,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

function Filters() {
  const store = useStore();

  const queryParams = qs.stringify({
    key: store.report.key,
    value: store.report.value,
    valueFinal: store.report.valueFinal,
    initialDate: store.dateRange.initialDate,
    finalDate: store.dateRange.finalDate,
    limit: store.limit.qty,
  });

  useEffect(() => {
    store.setFilters(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return (
    <Container>
      <Stack spacing={2} direction="row" align="center">
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {store.dateRange.name}
          </MenuButton>
          <MenuList>
            <MenuOptionGroup
              defaultValue={store.dateRange.name}
              title="Filtro por período"
              type="radio"
            >
              {store.DateTypes.map((item) => (
                <MenuItemOption
                  value={item.name}
                  onClick={() => store.setDateRange(item)}
                >
                  {item.name}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Qtd. {store.limit.name}
          </MenuButton>
          <MenuList>
            <MenuOptionGroup
              defaultValue={store.limit.name}
              title="Filtrar quantidade de resultados"
              type="radio"
            >
              {store.LimitTypes.map((item) => (
                <MenuItemOption
                  value={item.name}
                  onClick={() => store.setLimit(item)}
                >
                  {item.name}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Stack>
    </Container>
  );
}

export default Filters;
