import { useEffect, useState } from "react";
import qs from "qs";
import Filters from "../../containers/Filters";
import useStore from "../../store";
import GroupBar from "../../components/GroupBar";
import Pie from "../../components/Pie";
import Menu from "../../containers/Menu";
import { Grid, GridItem, Heading, Spinner, Box } from "@chakra-ui/react";
import { Container, GridItemStyled, LoadingContainer } from "./styles";

const Reports = (props) => {
  const report = useStore((state) => state.report);
  const filters = useStore((state) => state.filters);
  const [reports, setReport] = useState(null);
  const [loading, setLoading] = useState(true);

  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const baseUrl = process.env.REACT_APP_API;

  const getReport = async () => {
    setLoading(true);
    fetch(
      `${baseUrl}attendances/persons/${query.personId}/reports?${filters}`,
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + query.token,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => setReport(data))
      .catch((e) => {
        console.log("RUIM", e.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner size="md" color="blue.500" thickness="3px" />
        <Box p={4}>Carregando relatórios...</Box>
      </LoadingContainer>
    );
  }

  const reportType = {
    Pie: <Pie data={reports} />,
    GroupBar: <GroupBar data={reports} />,
  };

  return (
    <Grid
      h="100vh"
      templateRows="repeat(1, 1fr)"
      templateColumns="repeat(5, 1fr)"
    >
      <GridItemStyled>
        <Menu />
      </GridItemStyled>
      {reports.length ? (
        <GridItem colSpan={4}>
          <Container>
            <Heading size="md">{report.name}</Heading>
          </Container>
          <Filters />
          {!loading ? (
            <>{reportType[report.charType]} </>
          ) : (
            <Container>Carregando relatório...</Container>
          )}
        </GridItem>
      ) : (
        <GridItem colSpan={4} padding={4}>
          <Heading size="sm">Não possui dados.</Heading>
          <p style={{ marginTop: 10 }}>
            Não conseguimos processar um relatório com os dados atuais.
          </p>
        </GridItem>
      )}
    </Grid>
  );
};

export default Reports;
