import create from "zustand";
import qs from "qs";
import { devtools } from "zustand/middleware";
import * as dateFns from "date-fns";
const today = Date.now();

const ReportTypes = [
  {
    name: "Estado Geral",
    key: "createdAt",
    value: "feelingScore",
    selected: true,
    charType: "Pie",
  },
  {
    name: "Freq. Cardíaca",
    key: "createdAt",
    value: "heartRate",
    valueFinal: "heartRate_final",
    selected: true,
    charType: "GroupBar",
  },
  {
    name: "Freq. Respiratória",
    key: "createdAt",
    value: "respiratoryRate",
    valueFinal: "respiratoryRate_final",
    selected: true,
    charType: "GroupBar",
  },
];

const DateTypes = [
  {
    name: "Todos",
    initialDate: "",
    finalDate: "",
    selected: true,
  },
  {
    name: "Mês atual",
    initialDate: dateFns.startOfMonth(today),
    finalDate: dateFns.endOfToday(),
    selected: true,
  },
  {
    name: "Mês passado",
    initialDate: dateFns.startOfMonth(dateFns.subMonths(today, -1)),
    finalDate: dateFns.endOfDay(
      dateFns.lastDayOfMonth(dateFns.subMonths(today, -1))
    ),
    selected: true,
  },
  {
    name: "Personalizado",
    key: "createdAt",
    value: "heartRate_final",
    selected: true,
  },
];

const LimitTypes = [
  {
    name: "5",
    qty: 5,
    selected: true,
  },
  {
    name: "10",
    qty: 10,
    selected: true,
  },
  {
    name: "20",
    qty: 20,
    selected: true,
  },
  {
    name: "30",
    qty: 30,
    selected: true,
  },
  {
    name: "60",
    qty: 60,
    selected: true,
  },
  {
    name: "90",
    qty: 90,
    selected: true,
  },
];

const queryParams = qs.stringify({
  key: ReportTypes[0].key,
  value: ReportTypes[0].value,
  valueFinal: ReportTypes[0].valueFinal,
  initialDate: DateTypes[0].initialDate,
  finalDate: DateTypes[0].finalDate,
  limit: LimitTypes[0].qty,
});

const useStore = create(
  devtools((set) => ({
    LimitTypes,
    DateTypes,
    ReportTypes,

    // reports
    report: ReportTypes[0],
    setReport: (value) => set(() => ({ report: value })),

    // dataRange
    dateRange: DateTypes[0],
    setDateRange: (value) => set(() => ({ dateRange: value })),

    // limit
    limit: LimitTypes[0],
    setLimit: (value) => set(() => ({ limit: value })),

    // filters
    filters: queryParams,
    setFilters: (value) => set(() => ({ filters: value })),
  }))
);

export default useStore;
