/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Container = styled.div`
  padding: 16px;
`;

export const ContainerReport = styled.div`
  height: 500px;
`;
