import React from "react";
import * as dateFns from "date-fns";
import { Pie } from "react-chartjs-2";

const colors = [
  {
    color: "#ea5041",
    value: 1,
  },
  {
    color: "#f28d54",
    value: 2,
  },
  {
    color: "#ffd254",
    value: 3,
  },
  {
    color: "#bacf69",
    value: 4,
  },
  {
    color: "#42ae5c",
    value: 5,
  },
];

function PieChart({ data }) {
  const dataParsed = data.map((item) => ({
    ...item,
    key: dateFns.format(dateFns.parseISO(item.key), "yyyy-MM-dd HH:MM:SS"),
    createdAt: dateFns.format(dateFns.parseISO(item.key), "dd/MM/yy"),
  }));

  const values = dataParsed.reduce(
    (acc, current) => {
      const index = parseInt(current.value, 10) - 1;
      acc[index] = acc[index] + 1;
      return acc;
    },
    [0, 0, 0, 0, 0]
  );

  const dataReport = {
    labels: [1, 2, 3, 4, 5],
    datasets: [
      {
        label: "Estado Geral",
        data: values,
        backgroundColor: colors.map((item) => item.color),
        borderColor: "white",
        borderWidth: 3,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: data.name,
      },
    },
  };

  return <Pie data={dataReport} options={options} />;
}

export default PieChart;
