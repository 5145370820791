import React from "react";
import * as dateFns from "date-fns";
import { Bar } from "react-chartjs-2";
import { Container, ContainerReport } from "./styles";
import percDiff from "../../utils/percentDiff";
import calcAverage from "../../utils/calcAverage";

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from "@chakra-ui/react";

function GroupBar({ data }) {
  const dataParsed = data.map((item) => ({
    ...item,
    key: dateFns.format(dateFns.parseISO(item.key), "yyyy-MM-dd HH:MM:SS"),
    createdAt: dateFns.format(dateFns.parseISO(item.key), "dd/MM/yy"),
  }));

  const labels = dataParsed.map((item) => item.createdAt);
  const values = dataParsed.map((item) => item.value).filter((item) => !!item);
  const valuesFinal = dataParsed
    .map((item) => item.valueFinal)
    .filter((item) => !!item);

  const dataReport = {
    labels: labels,
    datasets: [
      {
        label: "Inicial",
        data: values,
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "Final",
        data: valuesFinal,
        backgroundColor: "rgb(54, 162, 235)",
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: data.name,
      },
    },
  };

  return (
    <Container>
      <StatGroup>
        <Stat>
          <StatLabel>Media Inicial</StatLabel>
          <StatNumber>{calcAverage(values).avg.toFixed(1)}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Media Final</StatLabel>
          <StatNumber>{calcAverage(valuesFinal).avg.toFixed(1)}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            {percDiff(
              calcAverage(values).avg,
              calcAverage(valuesFinal).avg
            ).toFixed(1)}
            %
          </StatHelpText>
        </Stat>
      </StatGroup>
      <ContainerReport>
        <Bar data={dataReport} options={options} />
      </ContainerReport>
    </Container>
  );
}

export default GroupBar;
