/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { GridItem } from "@chakra-ui/react";

export const Container = styled.div`
  padding: 16px;
  display: flex;
`;

export const LoadingContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const GridItemStyled = styled(GridItem)`
  background-color: #fcfcfc;
  border-right: 1px solid #e6e6e6;
`;
