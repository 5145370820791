import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Reports from "./pages/reports";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <Suspense fallback="Loading...">
        <Router>
          <Switch>
            <Route exact path="/" component={Reports} />
          </Switch>
        </Router>
      </Suspense>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
